var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.id } },
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: { ok: _vm.ok, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _c(
                    "b-button",
                    {
                      staticStyle: { "margin-right": "auto" },
                      attrs: { size: "sm", variant: "secondary" },
                      on: { click: _vm.restoreDefaults },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.defaults")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.close },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c("div", { staticClass: "selector-navbar" }, [
            _c(
              "nav",
              { ref: "selector-nav", staticClass: "selector-nav" },
              [
                _c(
                  "PriorityNavigation",
                  { attrs: { ulClassName: "nav nav-pills" } },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "nav-pills nav-link active",
                        attrs: { name: "standard" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.selectorNavClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("a", { attrs: { href: "#", target: "_self" } }, [
                          _vm._v(_vm._s(_vm.$t("dashboard.standard"))),
                        ]),
                      ]
                    ),
                    _vm.origin == "home"
                      ? _c(
                          "li",
                          {
                            staticClass: "nav-pills nav-link",
                            attrs: { name: "progress" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.selectorNavClick.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("a", { attrs: { href: "#", target: "_self" } }, [
                              _vm._v(_vm._s(_vm.$t("dashboard.projects"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "li",
                      {
                        staticClass: "nav-pills nav-link",
                        attrs: { name: "launchpad" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.selectorNavClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("a", { attrs: { href: "#", target: "_self" } }, [
                          _vm._v(_vm._s(_vm.$t("dashboard.launchpads"))),
                        ]),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "nav-pills nav-link",
                        attrs: { name: "dataview" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.selectorNavClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("a", { attrs: { href: "#", target: "_self" } }, [
                          _vm._v(_vm._s(_vm.$t("dashboard.dataviews"))),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-tabs",
            {
              staticClass: "widget-tab-container",
              attrs: {
                "active-nav-item-class": "active",
                "content-class": "widget-tabs mt-3 specific-list",
                pills: "",
              },
              on: { "activate-tab": _vm.onTabSwitch },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "b-tab",
                { attrs: { title: "standard", "title-item-class": "mytab" } },
                [
                  _c("ListFilter", {
                    attrs: { termOnly: true },
                    on: { applyFilter: _vm.applyFilterStandard },
                  }),
                  _c(
                    "div",
                    { staticClass: "grid-toolbar border" },
                    [
                      _c("b-form-checkbox", {
                        staticClass: "secondary-checkbox select-state",
                        attrs: {
                          checked: _vm.nextSelectedToggleState("standard"),
                          indeterminate: _vm.indeterminateState("standard"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSelectedToggle("standard")
                          },
                        },
                      }),
                      _c(
                        "span",
                        [
                          _c("b-popover", {
                            attrs: {
                              target: "enablebtn",
                              placement: "top",
                              triggers: "hover",
                              content: _vm.nextVisibleToggleState("standard")
                                ? _vm.$t("dashboard.selector.set_enabled")
                                : _vm.$t("dashboard.selector.set_disabled"),
                            },
                          }),
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                id: "enablebtn",
                                disabled: _vm.standard.selected.length == 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onVisibleToggle("standard")
                                },
                              },
                            },
                            [
                              _vm.nextVisibleToggleState("standard")
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "eye"] },
                                  })
                                : _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "eye-slash"] },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("ag-grid-vue", {
                    staticClass:
                      "ag-theme-balham selector-grid-height-with-tabs",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "widget-grid",
                      gridOptions: _vm.standard.gridOptions,
                      columnDefs: _vm.standard.columnDefs,
                      context: _vm.context,
                      defaultColDef: _vm.defaultColDef,
                      getRowId: function (params) {
                        return params.data.uuId
                      },
                      overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
                      overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                      pagination: "",
                      paginationPageSize: 1000,
                      rowData: _vm.standard.rowData,
                      rowMultiSelectWithClick: "",
                      rowSelection: "multiple",
                      sideBar: false,
                      suppressDragLeaveHidesColumns: "",
                      suppressCellFocus: "",
                      suppressContextMenu: "",
                      suppressMultiSort: "",
                    },
                    on: { "grid-ready": _vm.onGridReadyStandard },
                  }),
                ],
                1
              ),
              _vm.origin == "home"
                ? _c(
                    "b-tab",
                    {
                      attrs: { title: "progress", "title-item-class": "mytab" },
                    },
                    [
                      _c("ListFilter", {
                        attrs: { termOnly: true },
                        on: { applyFilter: _vm.applyFilterProgress },
                      }),
                      _c(
                        "div",
                        { staticClass: "grid-toolbar border" },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "secondary-checkbox select-state",
                            attrs: {
                              checked: _vm.nextSelectedToggleState("progress"),
                              indeterminate: _vm.indeterminateState("progress"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onSelectedToggle("progress")
                              },
                            },
                          }),
                          _c(
                            "span",
                            [
                              _c("b-popover", {
                                attrs: {
                                  target: "visiblebtn",
                                  placement: "top",
                                  triggers: "hover",
                                  content: _vm.nextVisibleToggleState(
                                    "progress"
                                  )
                                    ? _vm.$t("dashboard.selector.set_enabled")
                                    : _vm.$t("dashboard.selector.set_disabled"),
                                },
                              }),
                              _c(
                                "b-btn",
                                {
                                  attrs: {
                                    id: "visiblebtn",
                                    disabled: _vm.progress.selected.length == 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onVisibleToggle("progress")
                                    },
                                  },
                                },
                                [
                                  _vm.nextVisibleToggleState("progress")
                                    ? _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "eye"] },
                                      })
                                    : _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "eye-slash"] },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("ag-grid-vue", {
                        staticClass:
                          "ag-theme-balham selector-grid-height-with-tabs",
                        staticStyle: { width: "100%" },
                        attrs: {
                          id: "widget-grid",
                          gridOptions: _vm.progress.gridOptions,
                          columnDefs: _vm.progress.columnDefs,
                          context: _vm.context,
                          defaultColDef: _vm.defaultColDef,
                          getRowId: function (params) {
                            return params.data.uuId
                          },
                          overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
                          pagination: "",
                          paginationPageSize: 1000,
                          rowData: _vm.progress.rowData,
                          rowMultiSelectWithClick: "",
                          rowSelection: "multiple",
                          sideBar: false,
                          suppressDragLeaveHidesColumns: "",
                          suppressCellFocus: "",
                          suppressContextMenu: "",
                          suppressMultiSort: "",
                        },
                        on: { "grid-ready": _vm.onGridReadyProgress },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-tab",
                { attrs: { title: "launchpad", "title-item-class": "mytab" } },
                [
                  _c("ListFilter", {
                    attrs: { termOnly: true },
                    on: { applyFilter: _vm.applyFilterLaunchpad },
                  }),
                  _c(
                    "div",
                    { staticClass: "grid-toolbar border" },
                    [
                      _c("b-form-checkbox", {
                        staticClass: "secondary-checkbox select-state",
                        attrs: {
                          checked: _vm.nextSelectedToggleState("launchpad"),
                          indeterminate: _vm.indeterminateState("launchpad"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSelectedToggle("launchpad")
                          },
                        },
                      }),
                      _c(
                        "span",
                        [
                          _c("b-popover", {
                            attrs: {
                              target: "BTN_ADD_" + _vm.id,
                              placement: "top",
                              triggers: "hover",
                              content: _vm.$t("button.add"),
                            },
                          }),
                          _c(
                            "b-btn",
                            {
                              attrs: { id: "BTN_ADD_" + _vm.id },
                              on: {
                                click: function ($event) {
                                  return _vm.modalOpen(true)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { color: "var(--grid-toolbar-button)" },
                                attrs: { icon: ["far", "plus"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("b-popover", {
                            attrs: {
                              target: "BTN_EDIT_" + _vm.id,
                              placement: "top",
                              triggers: "hover",
                              content: _vm.$t("button.edit"),
                            },
                          }),
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled: _vm.launchpad.selected.length != 1,
                                id: "BTN_EDIT_" + _vm.id,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.modalOpen(false)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "pen-to-square"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("b-popover", {
                            attrs: {
                              target: "BTN_DUPLICATE_" + _vm.id,
                              placement: "top",
                              triggers: "hover",
                              content: _vm.$t("button.duplicate"),
                            },
                          }),
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled: _vm.launchpad.selected.length != 1,
                                id: "BTN_DUPLICATE_" + _vm.id,
                              },
                              on: { click: _vm.showDuplicateDialog },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "clone"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("b-popover", {
                            attrs: {
                              target: "BTN_DELETE_" + _vm.id,
                              placement: "top",
                              triggers: "hover",
                              content: _vm.$t("button.delete"),
                            },
                          }),
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled: _vm.launchpad.selected.length == 0,
                                id: "BTN_DELETE_" + _vm.id,
                              },
                              on: { click: _vm.rowDelete },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "trash-can"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("b-popover", {
                            attrs: {
                              target: "BTN_VISIBLE_" + _vm.id,
                              placement: "top",
                              triggers: "hover",
                              content: _vm.nextVisibleToggleState("launchpad")
                                ? _vm.$t("dashboard.selector.set_enabled")
                                : _vm.$t("dashboard.selector.set_disabled"),
                            },
                          }),
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled: _vm.launchpad.selected.length == 0,
                                id: "BTN_VISIBLE_" + _vm.id,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onVisibleToggle("launchpad")
                                },
                              },
                            },
                            [
                              _vm.nextVisibleToggleState("launchpad")
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "eye"] },
                                  })
                                : _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "eye-slash"] },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("ag-grid-vue", {
                    staticClass:
                      "ag-theme-balham selector-grid-height-with-tabs",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "widget-grid",
                      gridOptions: _vm.launchpad.gridOptions,
                      columnDefs: _vm.launchpad.columnDefs,
                      context: _vm.context,
                      defaultColDef: _vm.defaultColDef,
                      getRowId: function (params) {
                        return params.data.uuId
                      },
                      overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
                      pagination: "",
                      paginationPageSize: 1000,
                      rowData: _vm.launchpad.rowData,
                      rowMultiSelectWithClick: "",
                      rowSelection: "multiple",
                      sideBar: false,
                      suppressDragLeaveHidesColumns: "",
                      suppressCellFocus: "",
                      suppressContextMenu: "",
                      suppressMultiSort: "",
                    },
                    on: { "grid-ready": _vm.onGridReadyLaunchpad },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "dataview", "title-item-class": "mytab" } },
                [
                  _c("ListFilter", {
                    attrs: { termOnly: true },
                    on: { applyFilter: _vm.applyFilterDataview },
                  }),
                  _c(
                    "div",
                    { staticClass: "grid-toolbar border" },
                    [
                      _c("b-form-checkbox", {
                        staticClass: "secondary-checkbox select-state",
                        attrs: {
                          checked: _vm.nextSelectedToggleState("dataview"),
                          indeterminate: _vm.indeterminateState("dataview"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSelectedToggle("dataview")
                          },
                        },
                      }),
                      _c(
                        "span",
                        [
                          _c("b-popover", {
                            attrs: {
                              target: "BTN_DATAVIEW_ADD_" + _vm.id,
                              placement: "top",
                              triggers: "hover",
                              content: _vm.$t("button.add"),
                            },
                          }),
                          _c(
                            "b-btn",
                            {
                              attrs: { id: "BTN_DATAVIEW_ADD_" + _vm.id },
                              on: {
                                click: function ($event) {
                                  _vm.dataviewSelectorShow = true
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { color: "var(--grid-toolbar-button)" },
                                attrs: { icon: ["far", "plus"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("b-popover", {
                            attrs: {
                              target: "BTN_DATAVIEW_EDIT_" + _vm.id,
                              placement: "top",
                              triggers: "hover",
                              content: _vm.$t("button.edit"),
                            },
                          }),
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled: _vm.dataview.selected.length != 1,
                                id: "BTN_DATAVIEW_EDIT_" + _vm.id,
                              },
                              on: { click: _vm.editDataview },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "pen-to-square"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("b-popover", {
                            attrs: {
                              target: "BTN_DATAVIEW_DELETE_" + _vm.id,
                              placement: "top",
                              triggers: "hover",
                              content: _vm.$t("button.delete"),
                            },
                          }),
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled: _vm.dataview.selected.length == 0,
                                id: "BTN_DATAVIEW_DELETE_" + _vm.id,
                              },
                              on: { click: _vm.dataviewRowDelete },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "trash-can"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("b-popover", {
                            attrs: {
                              target: "BTN_DATAVIEW_VISIBLE_" + _vm.id,
                              placement: "top",
                              triggers: "hover",
                              content: _vm.nextVisibleToggleState("dataview")
                                ? _vm.$t("dashboard.selector.set_enabled")
                                : _vm.$t("dashboard.selector.set_disabled"),
                            },
                          }),
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled: _vm.dataview.selected.length == 0,
                                id: "BTN_DATAVIEW_VISIBLE_" + _vm.id,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onVisibleToggle("dataview")
                                },
                              },
                            },
                            [
                              _vm.nextVisibleToggleState("dataview")
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "eye"] },
                                  })
                                : _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "eye-slash"] },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("ag-grid-vue", {
                    staticClass:
                      "ag-theme-balham selector-grid-height-with-tabs",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "widget-grid",
                      gridOptions: _vm.dataview.gridOptions,
                      columnDefs: _vm.dataview.columnDefs,
                      context: _vm.context,
                      defaultColDef: _vm.defaultColDef,
                      getRowId: function (params) {
                        return params.data.uuId
                      },
                      overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
                      pagination: "",
                      paginationPageSize: 1000,
                      rowData: _vm.dataview.rowData,
                      rowMultiSelectWithClick: "",
                      rowSelection: "multiple",
                      sideBar: false,
                      suppressDragLeaveHidesColumns: "",
                      suppressCellFocus: "",
                      suppressContextMenu: "",
                      suppressMultiSort: "",
                    },
                    on: { "grid-ready": _vm.onGridReadyDataview },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.launchpadShow
        ? _c("DashboardLaunchpadModal", {
            attrs: { widget: _vm.launchpadSelected },
            on: {
              added: _vm.launchpadAdded,
              updated: _vm.launchpadUpdated,
              cancel: _vm.modalCancel,
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.duplicateTitle,
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.duplicateCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.duplicateInProgress
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: "",
                            size: "sm",
                            variant: "success",
                          },
                        },
                        [
                          _c("b-spinner", {
                            attrs: { small: "", type: "grow" },
                          }),
                          _vm._v(_vm._s(_vm.$t("button.processing"))),
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.duplicateOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.duplicate")))]
                      ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.duplicateShow,
            callback: function ($$v) {
              _vm.duplicateShow = $$v
            },
            expression: "duplicateShow",
          },
        },
        [
          _c(
            "b-form-group",
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "name",
                      type: "text",
                      "data-vv-as": _vm.$t("dashboard.launchpad.field.name"),
                      "data-vv-name": "duplicate.name",
                      "data-vv-delay": "500",
                      trim: "",
                    },
                    model: {
                      value: _vm.duplicateName,
                      callback: function ($$v) {
                        _vm.duplicateName = $$v
                      },
                      expression: "duplicateName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showDuplicateNameError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v(
                    "  " + _vm._s(_vm.errors.first("duplicate.name")) + " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dashboard.launchpad.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.handleConfirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteModalShow,
            callback: function ($$v) {
              _vm.confirmDeleteModalShow = $$v
            },
            expression: "confirmDeleteModalShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    _vm.launchpad.selected.length > 1
                      ? "dashboard.launchpad.confirmation.delete_plural"
                      : "dashboard.launchpad.confirmation.delete_single"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dashboard.dataview.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.handleConfirmDataviewDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteDataviewModalShow,
            callback: function ($$v) {
              _vm.confirmDeleteDataviewModalShow = $$v
            },
            expression: "confirmDeleteDataviewModalShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    _vm.dataview.selected.length > 1
                      ? "dashboard.dataview.confirmation.delete_plural"
                      : "dashboard.dataview.confirmation.delete_single"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c("DataviewSelectorModal", {
        attrs: {
          title: _vm.$t("dataview.select_dataview_title"),
          show: _vm.dataviewSelectorShow,
          "ok-title": _vm.$t("button.select"),
        },
        on: {
          "update:show": function ($event) {
            _vm.dataviewSelectorShow = $event
          },
          ok: _vm.dataviewSelectOk,
        },
      }),
      _vm.selectDataviewShow
        ? _c("DashboardDataviewModal", {
            attrs: { widget: _vm.dataviewWidget },
            on: { ok: _vm.confirmSelectOk, cancel: _vm.dataviewModalCancel },
          })
        : _vm._e(),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }