<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @ok="ok" @hidden="hidden" ref="modal">

      <div class="selector-navbar">
        <nav class="selector-nav" ref="selector-nav">
          <PriorityNavigation ulClassName="nav nav-pills">
            <li name="standard" class="nav-pills nav-link active" @click.stop="selectorNavClick">
              <a href="#" target="_self">{{$t('dashboard.standard')}}</a>
            </li>
            <li v-if="origin=='home'" name="progress" class="nav-pills nav-link" @click.stop="selectorNavClick">
              <a href="#" target="_self">{{$t('dashboard.projects')}}</a>
            </li>
            <li name="launchpad" class="nav-pills nav-link" @click.stop="selectorNavClick">
              <a href="#" target="_self">{{$t('dashboard.launchpads')}}</a>
            </li>
            <li name="dataview" class="nav-pills nav-link" @click.stop="selectorNavClick">
              <a href="#" target="_self">{{$t('dashboard.dataviews')}}</a>
            </li>
          </PriorityNavigation>
        </nav>
      </div>

      <b-tabs v-model="activeTab" @activate-tab="onTabSwitch"
        class="widget-tab-container"
        active-nav-item-class="active"
        content-class="widget-tabs mt-3 specific-list" pills>

        <b-tab title="standard" title-item-class="mytab">
          <ListFilter @applyFilter="applyFilterStandard" :termOnly='true'/>

          <div class="grid-toolbar border">
            <b-form-checkbox @change="onSelectedToggle('standard')" :checked="nextSelectedToggleState('standard')" :indeterminate="indeterminateState('standard')" class="secondary-checkbox select-state"></b-form-checkbox>
            
            <span>
              <b-popover
                target="enablebtn"
                placement="top"
                triggers="hover"
                :content="nextVisibleToggleState('standard') ? $t('dashboard.selector.set_enabled') : $t('dashboard.selector.set_disabled')">
              </b-popover>
              <b-btn id="enablebtn" :disabled="standard.selected.length == 0" @click="onVisibleToggle('standard')">
                <font-awesome-icon v-if="nextVisibleToggleState('standard')" :icon="['far','eye']"/>
                <font-awesome-icon v-else :icon="['far','eye-slash']"/>
              </b-btn>
            </span>

          </div>

          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="widget-grid"
                :gridOptions="standard.gridOptions"
                @grid-ready="onGridReadyStandard"
                :columnDefs="standard.columnDefs"
                :context="context"
                :defaultColDef="defaultColDef"
                :getRowId="params => params.data.uuId"
                :overlayNoRowsTemplate="overlayNoRowsTemplate"
                :overlayLoadingTemplate="overlayLoadingTemplate"
                pagination
                :paginationPageSize="1000"
                :rowData="standard.rowData"
                rowMultiSelectWithClick
                :rowSelection="'multiple'"
                :sideBar="false"
                suppressDragLeaveHidesColumns
                suppressCellFocus
                suppressContextMenu
                suppressMultiSort
                >
          </ag-grid-vue>
        </b-tab>
        <b-tab v-if="origin=='home'" title="progress" title-item-class="mytab">
          <ListFilter @applyFilter="applyFilterProgress" :termOnly='true'/>

          <div class="grid-toolbar border">
            <b-form-checkbox @change="onSelectedToggle('progress')" :checked="nextSelectedToggleState('progress')" :indeterminate="indeterminateState('progress')" class="secondary-checkbox select-state"></b-form-checkbox>
            
            <span>
              <b-popover
                target="visiblebtn"
                placement="top"
                triggers="hover"
                :content="nextVisibleToggleState('progress') ? $t('dashboard.selector.set_enabled') : $t('dashboard.selector.set_disabled')">
              </b-popover>
              <b-btn id="visiblebtn" :disabled="progress.selected.length == 0" @click="onVisibleToggle('progress')">
                <font-awesome-icon v-if="nextVisibleToggleState('progress')" :icon="['far','eye']"/>
                <font-awesome-icon v-else :icon="['far','eye-slash']"/>
              </b-btn>
            </span>

          </div>

          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="widget-grid"
                :gridOptions="progress.gridOptions"
                @grid-ready="onGridReadyProgress"
                :columnDefs="progress.columnDefs"
                :context="context"
                :defaultColDef="defaultColDef"
                :getRowId="params => params.data.uuId"
                :overlayNoRowsTemplate="overlayNoRowsTemplate"
                pagination
                :paginationPageSize="1000"
                :rowData="progress.rowData"
                rowMultiSelectWithClick
                :rowSelection="'multiple'"
                :sideBar="false"
                suppressDragLeaveHidesColumns
                suppressCellFocus
                suppressContextMenu
                suppressMultiSort
                >
          </ag-grid-vue>
        </b-tab>
        <b-tab title="launchpad" title-item-class="mytab">
          <ListFilter @applyFilter="applyFilterLaunchpad" :termOnly='true'/>
          
          <div class="grid-toolbar border">
            <b-form-checkbox @change="onSelectedToggle('launchpad')" :checked="nextSelectedToggleState('launchpad')" :indeterminate="indeterminateState('launchpad')" class="secondary-checkbox select-state"></b-form-checkbox>

            <span>
              <b-popover
                :target="`BTN_ADD_${id}`"
                placement="top"
                triggers="hover"
                :content="$t('button.add')">
              </b-popover>
              <b-btn :id="`BTN_ADD_${id}`" @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
            </span>
            <span>
              <b-popover
                :target="`BTN_EDIT_${id}`"
                placement="top"
                triggers="hover"
                :content="$t('button.edit')">
              </b-popover>
              <b-btn :disabled="launchpad.selected.length != 1" :id="`BTN_EDIT_${id}`" @click="modalOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
            </span>
            <span>
              <b-popover
                :target="`BTN_DUPLICATE_${id}`"
                placement="top"
                triggers="hover"
                :content="$t('button.duplicate')">
              </b-popover>
              <b-btn :disabled="launchpad.selected.length != 1" :id="`BTN_DUPLICATE_${id}`" @click="showDuplicateDialog"><font-awesome-icon :icon="['far','clone']"/></b-btn>  
            </span>
            <span>
              <b-popover
                :target="`BTN_DELETE_${id}`"
                placement="top"
                triggers="hover"
                :content="$t('button.delete')">
              </b-popover>
              <b-btn :disabled="launchpad.selected.length == 0" :id="`BTN_DELETE_${id}`" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
            </span>

            <span>
              <b-popover
                :target="`BTN_VISIBLE_${id}`"
                placement="top"
                triggers="hover"
                :content="nextVisibleToggleState('launchpad') ? $t('dashboard.selector.set_enabled') : $t('dashboard.selector.set_disabled')">
              </b-popover>
              <b-btn :disabled="launchpad.selected.length == 0" :id="`BTN_VISIBLE_${id}`" @click="onVisibleToggle('launchpad')">
                <font-awesome-icon v-if="nextVisibleToggleState('launchpad')" :icon="['far','eye']"/>
                <font-awesome-icon v-else :icon="['far','eye-slash']"/>
              </b-btn>
            </span>
          </div>

          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="widget-grid"
                :gridOptions="launchpad.gridOptions"
                @grid-ready="onGridReadyLaunchpad"
                :columnDefs="launchpad.columnDefs"
                :context="context"
                :defaultColDef="defaultColDef"
                :getRowId="params => params.data.uuId"
                :overlayNoRowsTemplate="overlayNoRowsTemplate"
                pagination
                :paginationPageSize="1000"
                :rowData="launchpad.rowData"
                rowMultiSelectWithClick
                :rowSelection="'multiple'"
                :sideBar="false"
                suppressDragLeaveHidesColumns
                suppressCellFocus
                suppressContextMenu
                suppressMultiSort
                >
          </ag-grid-vue>
        </b-tab>
        <b-tab title="dataview" title-item-class="mytab">
          <ListFilter @applyFilter="applyFilterDataview" :termOnly='true'/>
          
          <div class="grid-toolbar border">
            <b-form-checkbox @change="onSelectedToggle('dataview')" :checked="nextSelectedToggleState('dataview')" :indeterminate="indeterminateState('dataview')" class="secondary-checkbox select-state"></b-form-checkbox>
            <span>
              <b-popover
                :target="`BTN_DATAVIEW_ADD_${id}`"
                placement="top"
                triggers="hover"
                :content="$t('button.add')">
              </b-popover>
              <b-btn :id="`BTN_DATAVIEW_ADD_${id}`" @click="dataviewSelectorShow = true"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
            </span>
            <span>
              <b-popover
                :target="`BTN_DATAVIEW_EDIT_${id}`"
                placement="top"
                triggers="hover"
                :content="$t('button.edit')">
              </b-popover>
              <b-btn :disabled="dataview.selected.length != 1" :id="`BTN_DATAVIEW_EDIT_${id}`" @click="editDataview"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
            </span>
            <span>
              <b-popover
                :target="`BTN_DATAVIEW_DELETE_${id}`"
                placement="top"
                triggers="hover"
                :content="$t('button.delete')">
              </b-popover>
              <b-btn :disabled="dataview.selected.length == 0" :id="`BTN_DATAVIEW_DELETE_${id}`" @click="dataviewRowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
            </span>
            
            <span>
              <b-popover
                :target="`BTN_DATAVIEW_VISIBLE_${id}`"
                placement="top"
                triggers="hover"
                :content="nextVisibleToggleState('dataview') ? $t('dashboard.selector.set_enabled') : $t('dashboard.selector.set_disabled')">
              </b-popover>
              <b-btn :disabled="dataview.selected.length == 0" :id="`BTN_DATAVIEW_VISIBLE_${id}`" @click="onVisibleToggle('dataview')">
                <font-awesome-icon v-if="nextVisibleToggleState('dataview')" :icon="['far','eye']"/>
                <font-awesome-icon v-else :icon="['far','eye-slash']"/>
              </b-btn>
            </span>
          </div>

          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="widget-grid"
                :gridOptions="dataview.gridOptions"
                @grid-ready="onGridReadyDataview"
                :columnDefs="dataview.columnDefs"
                :context="context"
                :defaultColDef="defaultColDef"
                :getRowId="params => params.data.uuId"
                :overlayNoRowsTemplate="overlayNoRowsTemplate"
                pagination
                :paginationPageSize="1000"
                :rowData="dataview.rowData"
                rowMultiSelectWithClick
                :rowSelection="'multiple'"
                :sideBar="false"
                suppressDragLeaveHidesColumns
                suppressCellFocus
                suppressContextMenu
                suppressMultiSort
                >
          </ag-grid-vue>
        </b-tab>
      </b-tabs>

      <template v-slot:modal-footer="{ }" style="justify-content: start;">
        <b-button size="sm" variant="secondary" @click="restoreDefaults" style="margin-right: auto">
          {{ $t('button.defaults') }}
        </b-button>

        <b-button size="sm" variant="danger" @click="close">{{ $i18n.t('button.close') }}</b-button>
      </template>
    </b-modal>
    
    <DashboardLaunchpadModal
      v-if="launchpadShow"
      :widget="launchpadSelected"
      @added="launchpadAdded"
      @updated="launchpadUpdated"
      @cancel="modalCancel"
      />
    
    <b-modal :title="duplicateTitle"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group>
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('dashboard.launchpad.field.name')"
            data-vv-name="duplicate.name"
            data-vv-delay="500"
            trim
            v-model="duplicateName"/>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicate.name') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('dashboard.launchpad.confirmation.title_delete')"
        v-model="confirmDeleteModalShow"
        @ok="handleConfirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{  $t(launchpad.selected.length > 1?'dashboard.launchpad.confirmation.delete_plural':'dashboard.launchpad.confirmation.delete_single') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('dashboard.dataview.confirmation.title_delete')"
        v-model="confirmDeleteDataviewModalShow"
        @ok="handleConfirmDataviewDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{  $t(dataview.selected.length > 1?'dashboard.dataview.confirmation.delete_plural':'dashboard.dataview.confirmation.delete_single') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <DataviewSelectorModal :title="$t('dataview.select_dataview_title')"
        :show.sync="dataviewSelectorShow"
        :ok-title="$t('button.select')"
        @ok="dataviewSelectOk"
        />
    
    <DashboardDataviewModal
      v-if="selectDataviewShow"
      :widget="dataviewWidget"
      @ok="confirmSelectOk"
      @cancel="dataviewModalCancel"
      />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';

import { strRandom  } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';

import ListFilter from '@/components/ListFilter/ListFilter';
import { viewProfileService } from '@/services';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import VisibleCellRenderer from '@/components/Aggrid/CellRenderer/Visible';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import RebateCellRenderer from '@/components/Aggrid/CellRenderer/Rebate';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';

function getColumnDefs(c) {
  return {
    colId: c.colId
    , width: c.actualWidth
    , sort: c.sort != null? c.sort : null
    , sortIndex: c.sortIndex != null? c.sortIndex : null
  }
}

export default {
  name: 'DashboardWidgetSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    DashboardLaunchpadModal: () => import('./DashboardLaunchpadModal.vue'),
    DashboardDataviewModal: () => import('./DashboardDataviewModal.vue'),
    ListFilter,
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    DataviewSelectorModal: () => import('@/components/modal/DataviewSelectorModal'),
    PriorityNavigation,
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    'detailLinkCellRenderer': DetailLinkCellRenderer,
    'visibleCellRenderer': VisibleCellRenderer,
    'dateTimeCellRenderer': DateTimeCellRenderer,
    'rebateCellRenderer': RebateCellRenderer,
    'costCellRenderer': CostCellRenderer
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    },
    preselected: {
      type: String,
      default: null
    },
    standardWidgets:   {
      type: Array, required: true, default: function() {return []}
    },
    progressWidgets:   {
      type: Array, required: true, default: function() {return []}
    },
    launchpadWidgets:   {
      type: Array, required: true, default: function() {return []}
    },
    dataviewWidgets:   {
      type: Array, required: true, default: function() {return []}
    },
    origin: {
      type: String,
      default: 'home' // home or project
    },
    // Map of projects by UUID to get column data for progress tab
    projects: {
      type: Object, required: true, default: function() {return {}}
    }
  },
  data: function() {
    return {
      id: `DASHBOARD_WIDGET_LIST_${strRandom(5)}`,
      modalShow: false,
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      settings: {},
      standard: {
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        selected: [],
        rowData: null,
        columnDefs: [
          {
            headerName: this.$t('dashboard.selector.label'),
            field: 'label',
            checkboxSelection: true,
            lockVisible: true,
            minWidth: 200,
            flex: 1,
            hide: false,
            sort: 'asc',
            valueGetter: (params) => {
              if (params.data.type) {
                if (params.data.type === 'myschedule') {
                  return this.$t(`permission.my_schedule`);
                }
                else if (params.data.type === 'department_staff') {
                  return this.$t(`permission.department_staff`);
                }
                else if (params.data.type === 'departmentstaff') {
                  return this.$t(`permission.department_staff`);
                }
                else if (params.data.type === 'activetasks') {
                  return this.$t(`permission.active_tasks`);
                }
                else if (params.data.type === 'activeprojects') {
                  return this.$t(`permission.active_projects`);
                }
                else if (params.data.type === 'hskills') {
                  return this.$t(`permission.staff_skills`);
                }
                else if (params.data.type === 'mytasks') {
                  return this.$t(`permission.my_tasks`);
                }
                return this.$t(`permission.${params.data.type}`);
              }
              return params.data.label;
            }
          },
          {
            headerName: this.$t('dashboard.selector.displayed'),
            field: 'enable',
            cellRenderer: 'visibleCellRenderer',
            lockVisible: true,
            hide: false,
            flex: 1,
          },
        ]
      },
      progress: {
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        selected: [],
        rowData: null,
        columnDefs: [
          {
            headerName: this.$t('dashboard.selector.project'),
            field: 'label',
            checkboxSelection: true,
            lockVisible: true,
            minWidth: 200,
            flex: 1,
            hide: false,
            sort: 'asc'
          },
          {
            headerName: this.$t('dashboard.selector.displayed'),
            field: 'enable',
            cellRenderer: 'visibleCellRenderer',
            lockVisible: true,
            hide: false,
            flex: 1,
          },
          {
            headerName: this.$t('project.field.customer'),
            field: 'customer',
            hide: false,
            valueGetter: function(params) {
              let project = params.context.componentParent.projects[params.data.i];
              return project.customer;
            }
          },
          {
            headerName: this.$t('project.field.progress'),
            field: 'progressPercent',
            hide: false,
            comparator: function(valueA, valueB) {
              return parseInt(valueA) - parseInt(valueB);
            },
            valueGetter: function(params) {
              let project = params.context.componentParent.projects[params.data.i];
              return project.progressPercent;
            }
          },
          {
            headerName: this.$t('project.field.startTime'),
            field: 'scheduleStart',
            cellRenderer: 'dateTimeCellRenderer',
            hide: true,
            valueGetter: function(params) {
              let project = params.context.componentParent.projects[params.data.i];
              return project.scheduleStart;
            }
          },
          {
            headerName: this.$t('project.field.closeTime'),
            field: 'scheduleFinish',
            cellRenderer: 'dateTimeCellRenderer',
            hide: true,
            valueGetter: function(params) {
              let project = params.context.componentParent.projects[params.data.i];
              return project.scheduleFinish;
            }
          },
          {
            headerName: this.$t('project.field.company'),
            field: 'company',
            hide: true,
            valueGetter: function(params) {
              let project = params.context.componentParent.projects[params.data.i];
              return project.company;
            }
          },
          {
            headerName: this.$t('project.field.location'),
            field: 'location',
            hide: true,
            valueGetter: function(params) {
              let project = params.context.componentParent.projects[params.data.i];
              return project.location;
            }
          },
          {
            headerName: this.$t('project.field.priority'),
            field: 'priority',
            hide: true,
            valueGetter: function(params) {
              let project = params.context.componentParent.projects[params.data.i];
              return project.priority;
            }
          },
          {
            headerName: this.$t('project.field.description'),
            field: 'description',
            hide: true,
            valueGetter: function(params) {
              let project = params.context.componentParent.projects[params.data.i];
              return project.description;
            }
          },
          {
            headerName: this.$t('project.field.status'),
            field: 'status',
            hide: true,
            valueGetter: function(params) {
              let project = params.context.componentParent.projects[params.data.i];
              return project.status;
            }
          },
          {
            headerName: this.$t('field.identifier_full'),
            field: 'identifier',
            hide: true,
            valueGetter: function(params) {
              let project = params.context.componentParent.projects[params.data.i];
              return project.identifier;              
            }
          }
        ]
      },
      launchpad: {
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        selected: [],
        rowData: null,
        columnDefs: [
          {
            headerName: this.$t('dashboard.selector.launchpad'),
            field: 'label',
            cellRenderer: 'detailLinkCellRenderer',
            checkboxSelection: true,
            lockVisible: true,
            minWidth: 200,
            flex: 1,
            hide: false,
            sort: 'asc'
          },
          {
            headerName: this.$t('dashboard.selector.displayed'),
            field: 'enable',
            cellRenderer: 'visibleCellRenderer',
            lockVisible: true,
            hide: false,
            flex: 1,
          },
          {
            headerName: this.$t('dashboard.selector.description'),
            field: 'description',
            hide: false,
            flex: 1,
          },
        ]
      },

      dataview: {
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        selected: [],
        rowData: null,
        columnDefs: [
          {
            headerName: this.$t('dashboard.dataview.field.name'),
            field: 'name',
            checkboxSelection: true,
            lockVisible: true,
            minWidth: 200,
            cellRenderer: 'detailLinkCellRenderer',
            flex: 1,
            hide: false,
            sort: 'asc'
          },
          {
            headerName: this.$t('dashboard.selector.dataview'),
            field: 'dataview.name',
            hide: false,
            flex: 1,
          },
          {
            headerName: this.$t('dashboard.dataview.field.component'),
            field: 'dataview.componentName',
            hide: false,
            flex: 1,
          },
          {
            headerName: this.$t('dashboard.selector.displayed'),
            field: 'enable',
            cellRenderer: 'visibleCellRenderer',
            lockVisible: true,
            hide: false,
            flex: 1,
          },
          {
            headerName: this.$t('dashboard.selector.description'),
            field: 'description',
            hide: true,
            flex: 1,
          },
        ]
      },
      context: null,
      defaultColDef: null,
      
      confirmDeleteModalShow: false,
      confirmDeleteDataviewModalShow: false,
      launchpadShow: false,
      launchpadSelected: null,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,
      dataviewSelectorShow: false,
      selectDataviewShow: false,
      dataviewWidget: {},
      
      activeTab: 0,

      duplicateShow: false,
      duplicateName: null,
      duplicateInProgress: false,
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    const self = this;

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: true,
      menuTabs: ['columnsMenuTab'],
    };
    this.getRowId = params => {
      return params.data.uuId;
    };
    this.context = {
      componentParent: self
    };

    this.prepareGrid('standard');
    this.prepareGrid('progress');
    this.prepareGrid('launchpad');
    this.prepareGrid('dataview');
  },
  created() {
    this.updateModalShow(this.show);
  },
  beforeDestroy() {
    this.userId = null;
    this.standard_newToProfile = null;
    this.progress_newToProfile = null;
    this.launchpad_newToProfile = null;
    this.dataview_newToProfile = null;
  },
  watch: {
    show(newValue) {
      if(newValue) {
        this.resetAlert();
        this.activeTab = 0;
        this.standard.rowData = this.standardWidgets;
        this.progress.rowData = this.progressWidgets;
        this.launchpad.rowData = this.launchpadWidgets;
        this.standard.selected = [];
        this.progress.selected = [];
        this.launchpad.selected = [];
        this.dataview.selected = [];
        this.loadViewProfile();

      } else {
        //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
        this.standard.gridApi = null;
        this.progress.gridApi = null;
        this.launchpad.gridApi = null;
        this.dataview.gridApi = null;
      }
      this.updateModalShow(newValue);
    },
    standardWidgets: {
      handler(newValue) {
        this.standard.rowData = newValue;
        if (this.standard.gridApi) {
          this.standard.gridApi.refreshCells();
        }
      },
      deep: true
    },
    progressWidgets: {
      handler(newValue) {
        this.progress.rowData = newValue;
        if (this.progress.gridApi) {
          this.progress.gridApi.refreshCells();
        }
      },
      deep: true
    },
    launchpadWidgets: {
      handler(newValue) {
        this.launchpad.rowData = newValue;
        if (this.launchpad.gridApi) {
          this.launchpad.gridApi.refreshCells();
        }
      },
      deep: true
    },
    dataviewWidgets: {
      handler(newValue) {
        this.dataview.rowData = newValue;
        if (this.dataview.gridApi) {
          this.dataview.gridApi.refreshCells();
        }
      },
      deep: true
    },

  },
  computed: {
    allowManage() {
      return true;
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('dashboard.selector.no_data') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title : this.$t('dashboard.selector.title_home');
    },
    showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name');
    },
    duplicateTitle() {
      return this.$t('dashboard.launchpad.title_duplicate');
    },
    tabList() {
      var l = [{ name: 'standard' }];
      if (this.origin == 'home') {
        l.push({ name: 'progress' });
      }
      l.push({ name: 'launchpad' });
      l.push({ name: 'dataview' });
      return l;
    }
  },
  methods: {
    detailLinkLabel(params) {
      if (params.node.data.type === 'dataview') {
        return params.node.data.name;
      }
      return params.node.data.label;
    },
    deepClone(jsonObj) {
      return JSON.parse(JSON.stringify(jsonObj));
    },
    colDefClone(colDef) {
      var def = this.deepClone(colDef);
      for(var i = 0; i < def.length; i++) {
        def[i].valueGetter = colDef[i].valueGetter;
        def[i].comparator = colDef[i].comparator;
      }
      return def;
    },
    onGridReadyStandard() {
      this.standard.gridApi = this.standard.gridOptions.api;
      this.standard.gridColumnApi = this.standard.gridOptions.columnApi;
    },
    onGridReadyProgress() {
      this.progress.gridApi = this.progress.gridOptions.api;
      this.progress.gridColumnApi = this.progress.gridOptions.columnApi;
    },
    onGridReadyLaunchpad() {
      this.launchpad.gridApi = this.launchpad.gridOptions.api;
      this.launchpad.gridColumnApi = this.launchpad.gridOptions.columnApi;
    },
    onGridReadyDataview() {
      this.dataview.gridApi = this.dataview.gridOptions.api;
      this.dataview.gridColumnApi = this.dataview.gridOptions.columnApi;
    },
    prepareGrid(gridType) {
      const profileKey = `dashboard_widget_selector_${gridType}`;
      const self = this;
      function getColumns() {
        if (gridType === 'standard') {
            return self.standard.gridOptions.columnApi.getAllDisplayedColumns();
          } else if (gridType === 'progress') {
            return self.progress.gridOptions.columnApi.getAllDisplayedColumns();
          } else if (gridType === 'launchpad') {
            return self.launchpad.gridOptions.columnApi.getAllDisplayedColumns();
          } else if (gridType === 'dataview') {
            return self.dataview.gridOptions.columnApi.getAllDisplayedColumns();
          }
      }
      const gridOptions = (uuIdFieldName, settings) => {
        return {
          onSelectionChanged: function(event) {
            settings.selected.splice(0, settings.selected.length, ...(event.api.getSelectedNodes().map(i => i.data[uuIdFieldName])));
          },
          onColumnVisible: function() {
            let columns = getColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          },
          onSortChanged: function() {
            let columns = getColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          },
          onDragStopped: function() {
            let columns = getColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          },
          onFirstDataRendered: (event) => {
            const newToProfileKey = `${gridType}_newToProfile`;
            if (self[newToProfileKey] != null && self[newToProfileKey] == true) {
              self[newToProfileKey] = null;
              event.api.sizeColumnsToFit();
              self.$nextTick(() => {
                const columns = event.columnApi.getAllDisplayedColumns();
                self.settings[profileKey] = columns.map(c => getColumnDefs(c));
                self.updateViewProfile();
              })
            }
          },
          getRowId : function(params) {
            return params.data[uuIdFieldName];
          },
          accentedSort: true // also makes it case-insensitive
        }
      }
      const uuIdFieldName = 'uuId'
      if (gridType == 'standard') {
        this.standard.gridOptions = gridOptions(uuIdFieldName, this.standard);
      } else if (gridType == 'progress') {
        this.progress.gridOptions = gridOptions(uuIdFieldName, this.progress);
      } else if (gridType == 'launchpad') {
        this.launchpad.gridOptions = gridOptions(uuIdFieldName, this.launchpad);
      } else if (gridType == 'dataview') {
        this.dataview.gridOptions = gridOptions(uuIdFieldName, this.dataview);
      }
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    handleConfirmDeleteOk() {
      let widgets = this.launchpad.gridApi.getSelectedRows();
      this.$emit('launchpadRemoved', widgets);
    },
    handleConfirmDataviewDeleteOk() {
      let widgets = this.dataview.gridApi.getSelectedRows();
      this.$emit('dataviewRemoved', widgets);
    },
    modalOpen(isNew) {
      if (isNew) {
        this.launchpadSelected = null;
      } else {
        this.launchpadSelected = this.launchpad.gridApi.getSelectedRows()[0];
      }
      this.launchpadShow = true;
      this.resetAlert();
    },
    modalCancel() {
      this.launchpadShow = false;
    },
    dataviewModalCancel() {
      this.selectDataviewShow = false;
    },
    rowDelete() {
      this.resetAlert();
      this.confirmDeleteModalShow = true;
    },
    async editDataview() {
      const selected = this.dataview.gridApi.getSelectedRows()[0];
      this.dataviewWidget = selected;
      this.selectDataviewShow = true;
      
    },
    dataviewRowDelete() {
      this.resetAlert();
      this.confirmDeleteDataviewModalShow = true;
    },
    scrollToTop() {
      document.querySelector(`#${this.id}`).scrollIntoView();
    },
    ok() {

    },
    close() {
      this.$refs["modal"].hide();
      this.$emit('cancel')
    },
    hidden() {
      this.alert = null;
      this.$emit('update:show', false);
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    applyFilterStandard(pattern) {
      this.standard.gridOptions.api.setQuickFilter(pattern);
      // If adding a filter, unselect everything that doesn't match
      if (pattern) {
        this.standard.gridApi.forEachNode(n => {
          n.setSelected(false);
        });
      }
    },
    applyFilterProgress(pattern) {
      this.progress.gridOptions.api.setQuickFilter(pattern);
      // If adding a filter, unselect everything that doesn't match
      if (pattern) {
        this.progress.gridApi.forEachNode(n => {
          n.setSelected(false);
        });
      }
    },
    applyFilterLaunchpad(pattern) {
      this.launchpad.gridOptions.api.setQuickFilter(pattern);
      // If adding a filter, unselect everything that doesn't match
      if (pattern) {
        this.launchpad.gridApi.forEachNode(n => {
          n.setSelected(false);
        });
      }
    },
    applyFilterDataview(pattern) {
      this.dataview.gridOptions.api.setQuickFilter(pattern);
      // If adding a filter, unselect everything that doesn't match
      if (pattern) {
        this.dataview.gridApi.forEachNode(n => {
          n.setSelected(false);
        });
      }
    },
    showDuplicateDialog() {
      this.resetAlert();
      let widget = this.launchpad.gridApi.getSelectedRows()[0];
      const origName = widget.name;
      this.duplicateName = `${origName} (Copy)`;
      this.duplicateShow = true;
    },
    duplicateOk() {
      let widget = this.launchpad.gridApi.getSelectedRows()[0];
      let newWidget = this.deepClone(widget);
      delete newWidget.uuId;
      newWidget.name = this.duplicateName;
      newWidget.label = this.duplicateName;
      this.duplicateShow = false;
      this.launchpad.selected = [];
      this.$emit('launchpadDuplicated', newWidget);
    },
    duplicateCancel() {
      this.duplicateShow = false;
      this.errors.clear();
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings], this.userId)
      .then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.standard_newToProfile = true;
        this.progress_newToProfile = true;
        this.launchpad_newToProfile = true;
        this.dataview_newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadViewProfile() {
      const self = this;
      this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (!profileData || profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
          if (typeof self.settings.dashboard_widget_selector_standard !== 'undefined') {
            self.loadColumnSettings(self.standard, self.settings.dashboard_widget_selector_standard);
          } else {
            this.standard_newToProfile = true;
          }
          if (typeof self.settings.dashboard_widget_selector_progress !== 'undefined') {
            self.loadColumnSettings(self.progress, self.settings.dashboard_widget_selector_progress);
          } else {
            this.progress_newToProfile = true;
          }
          if (typeof self.settings.dashboard_widget_selector_launchpad !== 'undefined') {
            self.loadColumnSettings(self.launchpad, self.settings.dashboard_widget_selector_launchpad);
          } else {
            this.launchpad_newToProfile = true;
          }
          if (typeof self.settings.dashboard_widget_selector_dataview !== 'undefined') {
            self.loadColumnSettings(self.dataview, self.settings.dashboard_widget_selector_dataview);
          } else {
            this.dataview_newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      const columnDefs = this.colDefClone(data.columnDefs); // Clone to support restore defaults
      columns.forEach(function(col) {
        const index = columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          columnDefs.splice(idx++, 0, columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
        if (column.lockVisible) {
          column.hide = false;
        }
      }
      if (data != null && data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(columnDefs);
      }

      return false;
    },
    selectorNavClick(event) {
      /**
       * Update activeTab with the user choice.
       */
      const liElem = event.srcElement.closest('li');
      const activeName = liElem.getAttribute('name');
      const tabList = this.tabList;
      for(let i = 0, len = tabList.length; i < len; i++) {
        const tab = tabList[i];
        if(tab.name == activeName) {
          this.activeTab = i;
        }
      }
    },
    updateTabLink(activeIndex) {
      //Update the active state/style of links.
      const navbarElem = this.$refs['selector-nav'];
      if (navbarElem != null) {
        const childs = navbarElem.querySelectorAll('li');

        for (let i = 0; i < childs.length; i++) {
          if (activeIndex == i) {
            childs[i].classList.add('active');
          } else if(childs[i] != null && childs[i].classList != null) {
            childs[i].classList.remove('active');
          }
        }
      }
    },
    onTabSwitch(newTabIndex) {
      this.updateTabLink(newTabIndex);
    },
    async openDetail(id, row) {
      if (row.data.type === 'launchpad') {
        const index = this.launchpad.rowData.findIndex(d => d.uuId === row.data.uuId);
        this.launchpadSelected = this.launchpad.rowData[index];
        this.launchpadShow = true;
        this.resetAlert();
      }
      else if (row.data.type === 'dataview') {
        const selected = row.data;
        this.dataviewWidget = selected;
        this.selectDataviewShow = true;
        
      }
    },
    launchpadAdded(widget) {
     this.$emit('launchpadAdded', widget);
     this.launchpadShow = false;
     this.resetAlert({ msg: this.$t('dashboard.launchpad.create') });
    },
    launchpadUpdated(widget) {
      this.$emit('launchpadUpdated', widget);
      this.launchpadShow = false;
      this.resetAlert({ msg: this.$t('dashboard.launchpad.update') });
    },
    launchpadRemoved(widget) {
      this.$emit('launchpadUpdated', widget);
    },
    dataviewSelectOk(dataview) {
      const widget = { type: 'dataview', dataview: {uuId: dataview.uuId, name: dataview.name, isPublic: dataview.sharedVisibility == 'public'}, name: dataview.name };
      
      widget["w"] = 12;
      widget["h"] = 6;
      widget["i"] = "_";
      widget["label"] = 'Data View';
      widget["minw"] = 4;
      widget["minh"] = 4;
      this.dataviewWidget = widget;
      this.selectDataviewShow = true;
     
     this.dataviewSelectorShow = false;
    },
    nextVisibleToggleState(context) {
      var api;
      if (context == 'standard') {
        api = this.standard.gridApi;
      } else if (context == 'progress') {
        api = this.progress.gridApi;
      } else if (context == 'launchpad') {
        api = this.launchpad.gridApi;
      } else if (context == 'dataview') {
        api = this.dataview.gridApi;
      }
      if (!api) {
        return;
      }
      var nodes = api.getSelectedNodes();
      if (!nodes || nodes.length === 0) {
        return true;
      }

      return !(nodes[0].data.enable);
    },
    onVisibleToggle(context) {
      var api;
      var self = this;
      if (context == 'standard') {
        api = this.standard.gridApi;
      } else if (context == 'progress') {
        api = this.progress.gridApi;
      } else if (context == 'launchpad') {
        api = this.launchpad.gridApi;
      } else if (context == 'dataview') {
        api = this.dataview.gridApi;
      }
      
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('dashboard.saving');
      var state = this.nextVisibleToggleState(context);
      api.getSelectedNodes().forEach(n => {
        if (n.data.enable != state) {
          // setTimeout allows us to queue up events. We need to do
          // this because we need each widget to be placed on the board
          // (DOM+grid) before the next one in order to correctly evaluate
          // what's taking up space and find the right empty spot.
          setTimeout(() => {
            self.$emit('toggleWidget', { id: n.data.i, state: state });
            api.refreshCells();
          }, 1);
        }
      });
      
      setTimeout(() => {
        this.inProgressShow = false;
      });
    },
    nextSelectedToggleState(context) {
      var api;
      if (context == 'standard') {
        api = this.standard.gridApi;
      } else if (context == 'progress') {
        api = this.progress.gridApi;
      } else if (context == 'launchpad') {
        api = this.launchpad.gridApi;
      } else if (context == 'dataview') {
        api = this.dataview.gridApi;
      }
      if (!api) {
        return;
      }
      var selected = 0;
      api.forEachNodeAfterFilter(n => {
        if (n.isSelected()) {
          selected++;
        }
      });
      if (selected > 0) {
        return true;
      }
      return false;
    },
    indeterminateState(context) {
      var api;
      var selected;
      if (context == 'standard') {
        selected = this.standard.selected;
        api = this.standard.gridApi;
      } else if (context == 'progress') {
        selected = this.progress.selected;
        api = this.progress.gridApi;
      } else if (context == 'launchpad') {
        selected = this.launchpad.selected;
        api = this.launchpad.gridApi;
      } else if (context == 'dataview') {
        selected = this.dataview.selected;
        api = this.dataview.gridApi;
      }
      if (!api) {
        return;
      }
      return selected.length > 0 && selected.length < api.getDisplayedRowCount();
    },
    onSelectedToggle(context) {
      var api;
      if (context == 'standard') {
        api = this.standard.gridApi;
      } else if (context == 'progress') {
        api = this.progress.gridApi;
      } else if (context == 'launchpad') {
        api = this.launchpad.gridApi;
      } else if (context == 'dataview') {
        api = this.dataview.gridApi;
      }
      if (!api) {
        return;
      }
      var state = !this.nextSelectedToggleState(context);
      api.forEachNodeAfterFilter(n => {
        n.setSelected(state);
      });
      api.refreshCells();
    },
    restoreDefaults() {
      this.$emit('restoreDefaults');
      this.standard.gridOptions.api.setColumnDefs(this.colDefClone(this.standard.columnDefs));
      this.standard.gridOptions.onColumnVisible();
      this.standard.selected = [];

      this.launchpad.gridOptions.api.setColumnDefs(this.colDefClone(this.launchpad.columnDefs));
      this.launchpad.gridOptions.onColumnVisible();
      this.launchpad.selected = [];
      
      this.dataview.gridOptions.api.setColumnDefs(this.colDefClone(this.dataview.columnDefs));
      this.dataview.gridOptions.onColumnVisible();
      this.dataview.selected = [];
      
      
      if (this.origin == 'home') {
        this.progress.gridOptions.api.setColumnDefs(this.colDefClone(this.progress.columnDefs));
        this.progress.gridOptions.api.refreshCells();
        this.progress.gridOptions.onColumnVisible();
        this.progress.selected = [];
      }
    },
    confirmSelectOk(details) {
      if (!details.uuId) {
        this.$emit('dataviewAdded', details);
        this.resetAlert({ msg: this.$t('dashboard.dataview.create') });
      }
      else {
        this.$emit('dataviewUpdated', details);
        this.resetAlert({ msg: this.$t('dashboard.dataview.update') });
      }
      this.selectDataviewShow = false;
    }
  }
}


</script>

<style lang="scss">
.widget-tabs {
  padding: 0 3px;
}

.widget-tab-container ul[role=tablist] {
  display: none; //Hide nav bar generated by bootstrapVue tabs component. 
}

.loading {
  height: 144px;
  margin-left: calc(50% - 16px);
  margin-top: calc(25% - 16px);
}
</style>